<template>
  <!-- begin::kb-header -->
  <header class="kb-header" id="kb-header">
    <div class="header-component">
      <!-- header-title -->
      <div class="header-column header-title">
        안내사항 동의서
      </div>
      <!-- //header-title -->
      <!-- header-util -->
      <div class="header-column header-util left">
        <div class="util util-back">
          <a href="javascript:" class="util-actions util-actions-back" @click="goBack">
            <i class="icon-history-back"></i>
          </a>
        </div>
      </div>
      <!-- //header-util -->
    </div>
  </header>
  <!-- end::kb-header -->

  <!-- begin::kb-main -->
  <main class="kb-main" id="kb-facility">
    <!-- main-content -->
    <div class="main-content main-component">

      <div class="">
        <div class="view-contents">

          <!-- content-section: -->
          <article class="content-section">
            <header class="section-header">
              <h3 class="title">일산연수원 안내문</h3>
            </header>
            <div class="kb-form-fields kb-form-fields-v2 kb-form-fields-divider">
              <!-- kb-form-field:신청자 1 -->
              <div class="kb-form-field field-first">
                <div class="kb-form-row">
                  <label for="" class="kb-form-label"><span class="kb-form-label-text">신청자 1</span></label>
                </div>
                <div class="kb-form-row">
                  <span class="kb-form-text">{{ session.deptNm }} ・ {{ session.jbgdNm }} {{ session.jbpsNm }} ・ {{ session.lrnerNm }} | {{ session.lrnerId }} </span>
                </div>
              </div>
              <!-- kb-form-field:신청자 2 -->
              <div class="kb-form-field">
                <div class="kb-form-row">
                  <label for="" class="kb-form-label"><span class="kb-form-label-text">신청자 2</span></label>
                </div>
                <div class="kb-form-row">
                  <div class="kb-form-search">
                    <input @keyup.enter="searchUser" v-model="userSearchParam.searchText" type="text" class="kb-form-control" placeholder="직번을 입력하세요." />
                    <button class="kb-form-search-btn" @click="searchUser"><i class="icon-search"></i></button>
                  </div>
                </div>
              </div>
              <!-- kb-form-field:신청자 2 정보 -->
              <div class="kb-form-field" v-if="aplcntId2 != null">
                <div class="kb-form-row">
                  <label for="" class="kb-form-label"><span class="kb-form-label-text">신청자 2 정보</span></label>
                </div>
                <div class="kb-form-row">
                  <div class="kb-form-search">
                    <span class="kb-form-text" >{{ aplcntDeptNm2 }} ・ {{ aplcntNm2 }} | {{ aplcntId2 }} </span>
                  </div>
                </div>
              </div>
              <!-- kb-form-field -->
              <div class="kb-form-field">
                <div class="kb-form-row">
                  <p class="kb-form-text font-body14">일산연수원 운동장 사용 관련 연수 시설물의 효율적인 관리와 실질적인 영업 마케팅 추진 및 체육활동 지원을 위해 다음의 내용을 확인하고 위반시 사용제한에 동의합니다.</p>
                </div>
                <div class="kb-form-row row-divider">
                  <div class="kb-form-check check-agree">
                    <input type="checkbox" class="kb-form-check-input" id="chk" v-model="fcltAgree"/>
                    <label for="chk" class="kb-form-check-label"><strong class="kb-form-check-text">상기내용을 숙지하였으며 동의합니다.</strong></label>
                  </div>
                </div>
              </div>
              <!-- //kb-form-field -->
            </div>
          </article>

          <article class="content-section">
            <div class="kb-table kb-table-v2">
              <table style="min-width:678px">
                <colgroup>
                  <col style="width:136px" />
                  <col style="width:auto" />
                  <col style="width:176px" />
                </colgroup>
                <thead>
                <tr>
                  <th><span class="th-title">구분</span></th>
                  <th><span class="th-title">운영방식</span></th>
                  <th><span class="th-title">비고</span></th>
                </tr>
                </thead>
                <tbody class="align-left">
                <tr>
                  <td><span class="td-text">이용대상</span></td>
                  <td>
                    <p class="td-text">당행 동호회</p>
                    <p class="td-text text-muted">개인동호회 ∙ 외부팀 초청 경기 불허</p>
                  </td>
                  <td><span class="td-text"></span></td>
                </tr>
                <tr>
                  <td><span class="td-text">신청자</span></td>
                  <td><span class="td-text">당행직원 2명 필수 등록</span></td>
                  <td><span class="td-text">신청자는 이용자 관리 감독</span></td>
                </tr>
                <tr>
                  <td><span class="td-text">이용시간</span></td>
                  <td><span class="td-text">토요일 08:00 ~ 18:00 (동호회별 1일 1회 사용)</span></td>
                  <td><span class="td-text">2시간 단위 총 5회차</span></td>
                </tr>
                <tr>
                  <td><span class="td-text">이용시설</span></td>
                  <td><span class="td-text">대운동장, 화장실, 샤워장</span></td>
                  <td><span class="td-text"></span></td>
                </tr>
                <tr>
                  <td><span class="td-text">신청횟수</span></td>
                  <td><span class="td-text">개인별 월 1회</span></td>
                  <td><span class="td-text"></span></td>
                </tr>
                <tr>
                  <td><span class="td-text">신청방법</span></td>
                  <td>
                    <div class="text-group-1">
                      <div class="group">
                        <span class="title td-text">신청경로</span>
                        <div class="content">
                          <p class="td-text">생활연수 > 체육시설 > 체육시설 이용신청</p>
                        </div>
                      </div>
                      <div class="group">
                        <span class="title td-text">신청기간</span>
                        <div class="content">
                          <p class="td-text">매월 둘째 영업일 17:00 ~ &gt; 익월 예약화면 Open</p>
                          <p class="td-text text-muted">예) 22년 10월 둘째 영업일 (10.4 수요일) 17:00 &gt; 22년 11월 예약 화면 오픈</p>
                        </div>
                      </div>
                      <div class="group">
                        <span class="title td-text">취소기간</span>
                        <div class="content">
                          <p class="td-text">이용일 3일전 (기준일 초과 취소 불가)</p>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td><span class="td-text">22년 10월은 9.26(월) 예약</span></td>
                </tr>
                <tr>
                  <td><span class="td-text">패널티</span></td>
                  <td>
                    <div class="text-group-2">
                      <div class="group">
                        <strong class="title td-text">1년 사용제한</strong>
                        <div class="content">
                          <p class="td-text" data-bullet="-">음식물 반입금지 (배달음식 ∙ 컵라면 포함) &gt; 생수,음료,김밥 허용</p>
                          <p class="td-text" data-bullet="-">앰프 및 화기사용,애완동물 출입, 숙소동 및 강의동 출입, 소란행위 등</p>
                          <p class="td-text" data-bullet="-">원형광장 및 연수게시판 앞 주차금지 (정해진 주차구역 주차선 안에 주차)</p>
                          <p class="td-text" data-bullet="-">운동장내 흡연, 운동장 이용 종료 후 주변 정리 미실시</p>
                        </div>
                      </div>
                      <div class="group">
                        <strong class="title td-text">2년 사용제한</strong>
                        <div class="content">
                          <p class="td-text" data-bullet="-">신청자 불참 (2인 모두 참석, 신분증 지참 본인확인 및 상주 필수)</p>
                          <p class="td-text text-muted" data-bullet="">신청자 2인중 1인이라도 본인 확인 불가시 신청직원 2인 모두 패널티 적용</p>
                          <p class="td-text" data-bullet="-">이용대상자 위반 (당행 직원외 이용)</p>
                        </div>
                      </div>
                      <div class="group">
                        <strong class="title td-text">3년 사용제한</strong>
                        <div class="content">
                          <p class="td-text" data-bullet="-">연수원내 취사 및 음주, 질서문란(직원간 다툼 ∙ 폭행 등)</p>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td><span class="td-text"></span></td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="kb-table-bottom bottom-margin">
              <p data-bullet="-" class="text text-muted">연수원 출입(정문)  연수원 출입 (정문) 시 직원 신분증 제시</p>
              <p data-bullet="-" class="text text-muted">휴일 연수원내 경비 및 보안을 위해 직원신분증 미소지자는 연수원 출입 통제</p>
              <p data-bullet="" class="text text-muted">(<em class="text-muted">단, 신청자가 m-WiseNet을 통해 신분증 미지참자 본인확인 지원시 출입 가능</em>)</p>
              <p data-bullet="-" class="text text-muted">신청자 2인은 이용시간 30분 전까지 연수원 도착. 정문에 비치되어 있는 이용신청서에 서명</p>
              <p data-bullet="-" class="text text-muted">신청자 2인은 이용시간내 상주하며 이용직원 인솔 관리감독</p>
              <p data-bullet="-" class="text text-muted">이용시간 준수 및 이용 종료 후 운동장 주변 정리∙정돈 철저</p>
              <p data-bullet="-" class="text text-muted">연수 진행 또는 전행적인 행사 등 사정에 따라 미운영 또는 예약사항을 취소할 수 있음</p>
            </div>
          </article>
        </div>
      </div>
      <div class="page-buttons"><button class="kb-btn kb-btn-primary" @click="nextStep">동의하기</button></div>
    </div>
    <!-- //main-content -->
  </main>
  <!-- end::kb-main -->
</template>
<script>
import {computed, reactive, ref} from 'vue';
import {useStore} from "vuex";

import {useRouter} from "vue-router";
import {
  ACT_GET_USER_LIST,
  ACT_SET_FCLT_APLY_MOBILE_INFO,
} from "@/store/modules/trnct/trnct";
import {getItems, lengthCheck} from "@/assets/js/util";
import {useAlert} from "@/assets/js/modules/common/alert";

export default {
  name: 'DailyMobileSportsApplyStep2',
  components: {

  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const {showMessage} = useAlert();

    const session = computed(() => store.state.auth.session);

    const reserveVenue = ref('체육시설');

    const userList = ref([]);
    const userSearchParam = reactive( {
      searchText : store.state.trnct.mobileSportsApplyInfo.searchText,
      totalCount : 0
    });

    const aplcntId2 = ref(store.state.trnct.mobileSportsApplyInfo.aplcntId2);
    const aplcntNm2 = ref(store.state.trnct.mobileSportsApplyInfo.aplcntNm2);
    const aplcntDeptCd2 = ref(store.state.trnct.mobileSportsApplyInfo.aplcntDeptCd2);
    const aplcntDeptNm2 = ref(store.state.trnct.mobileSportsApplyInfo.aplcntDeptNm2);
    const fcltAgree = ref(false);

    const searchUser = () => {
      if(userSearchParam.searchText == null){
        showMessage('직번을 입력해주세요.');
        return;
      }

      if (userSearchParam.searchText == session.value.lrnerId) {        
        showMessage('신청자1과 다른 직번을 입력해주십시오.');
        userSearchParam.searchText = '';
        return;
      }      


      store.dispatch(`trnct/${ACT_GET_USER_LIST}`, {
        lrnerId: userSearchParam.searchText,
        pageNo: 1,
        pageSize: 100
      }).then(res=>{
        if(lengthCheck(res)){
          userList.value = getItems(res);
          console.log(userList.value)

          aplcntId2.value = userList.value[0].lrnerId;
          aplcntNm2.value = userList.value[0].lrnerNm;
          aplcntDeptCd2.value = userList.value[0].deptCd;
          aplcntDeptNm2.value = userList.value[0].deptNm;

        }else{
          showMessage('해당 직번이 없습니다.');
          userList.value = [];
          userSearchParam.totalCount = 0;

          aplcntId2.value = null;
          aplcntNm2.value = null;
          aplcntDeptCd2.value = null;
          aplcntDeptNm2.value = null;
        }
      }).catch(e=>{
        console.error(e);
      });
    }

    const nextStep = () => {
      if(aplcntId2.value == null  || aplcntId2.value == ''){
        showMessage('신청자2를 선택해주세요.');
        return;
      }

      if(fcltAgree.value == false){
        showMessage('동의완료를 체크 해주시기 바랍니다.');
        return;
      }

      store.commit(`trnct/${ACT_SET_FCLT_APLY_MOBILE_INFO}`,
          {
              aplcntId : store.state.auth.session.lrnerId,
              aplcntNm : store.state.auth.session.lrnerNm, //신청자명
              aplcntDeptCd : store.state.auth.session.deptCd, //신청자 부서코드
              aplcntDeptNm : store.state.auth.session.deptNm, //신장자 부서명
              aplcntId2: aplcntId2.value,
              aplcntNm2: aplcntDeptNm2.value,
              aplcntDeptCd2: aplcntDeptCd2.value,
              aplcntDeptNm2: aplcntDeptNm2.value,
              searchText: userSearchParam.searchText,
          }
      );

      router.push('/daily/apply/sports/step3');
    }

    const goBack = () => {
      router.go(-1);
    }

    return {
      reserveVenue,
      session,
      nextStep,
      aplcntId2,
      aplcntNm2,
      aplcntDeptCd2,
      aplcntDeptNm2,
      searchUser,
      userSearchParam,
      fcltAgree,
      goBack
    };
  },
};
</script>
